import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

async function fetchAllUsers() {
  const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users`);
  if (!res.ok) throw new Error("Failed to fetch users");
  return res.json(); // returns [{ _id, username }, ...]
}

export default function AllUsersPage() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await fetchAllUsers();
        setUsers(data);
      } catch (err) {
        setError(err.message || "Failed to load users");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          background: `
            linear-gradient(
              to bottom right,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.6)
            ),
            url('https://images.unsplash.com/photo-1557683316-973673baf926?q=80&w=2029&auto=format&fit=crop')
          `,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
        }}
      >
        <Typography
          variant="h5"
          sx={{ p: 2, bgcolor: "rgba(0,0,0,0.5)", borderRadius: 2 }}
        >
          Loading all users...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          background: `
            linear-gradient(
              to bottom right,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.6)
            ),
            url('https://images.unsplash.com/photo-1557683316-973673baf926?q=80&w=2029&auto=format&fit=crop')
          `,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
        }}
      >
        <Typography
          variant="h5"
          color="error"
          sx={{ p: 2, bgcolor: "rgba(0,0,0,0.5)", borderRadius: 2 }}
        >
          {error}
        </Typography>
      </Box>
    );
  }

  if (!users || users.length === 0) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          background: `
            linear-gradient(
              to bottom right,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.6)
            ),
            url('https://images.unsplash.com/photo-1557683316-973673baf926?q=80&w=2029&auto=format&fit=crop')
          `,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
        }}
      >
        <Box sx={{ p: 3, bgcolor: "rgba(0,0,0,0.5)", borderRadius: 2 }}>
          <Typography variant="h5" gutterBottom>
            No users found
          </Typography>
          <Typography variant="body1">
            Be the first to register and share your favorites!
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: `
          linear-gradient(
            to bottom right,
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.6)
          ),
          url('https://images.unsplash.com/photo-1557683316-973673baf926?q=80&w=2029&auto=format&fit=crop')
        `,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "#fff",
        py: 8,
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          py: 4,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(10px)",
          borderRadius: 2,
          boxShadow: "0 8px 32px rgba(0,0,0,0.4)",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 600, mb: 3 }}>
          All Lists
        </Typography>
        <List sx={{ px: 0 }}>
          {users.map((user, idx) => (
            <React.Fragment key={user._id}>
              <ListItem
                button
                onClick={() => navigate(`/u/${user.username}`)}
                sx={{
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255,0.1)",
                  },
                }}
              >
                <ListItemText
                  primary={user.username}
                  primaryTypographyProps={{
                    fontSize: "1rem",
                    fontWeight: 500,
                  }}
                />
              </ListItem>
              {idx < users.length - 1 && (
                <Divider sx={{ borderColor: "rgba(255,255,255,0.2)" }} />
              )}
            </React.Fragment>
          ))}
        </List>
      </Container>
    </Box>
  );
}
