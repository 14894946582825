import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import {
  Typography,
  Button,
  Container,
  Tabs,
  Tab,
  Box,
  TextField,
  Paper,
  List,
  ListItem,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  getUserLists,
  addToList,
  removeFromList,
  fetchMovies,
  fetchMusic,
  fetchGames,
  fetchBooks,
} from "../api";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASURE_KEY);
const ACCENT_COLOR = "#1DB954";
const categories = ["movies", "games", "music", "books"];

export default function ListPage() {
  ReactGA.send({
    hitType: "pageview",
    page: "/list",
    title: `Listing page hit by ${localStorage.getItem("username")}`,
  });
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem("token"));
  const [activeTab, setActiveTab] = useState(0); // 0 -> movies, 1 -> games, 2 -> music, 3 -> books
  const [lists, setLists] = useState({
    movies: [],
    games: [],
    music: [],
    books: [],
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (!token) {
      navigate("/auth");
    }
  }, [token, navigate]);

  useEffect(() => {
    (async () => {
      try {
        const userLists = await getUserLists(token);
        const newLists = { movies: [], games: [], music: [], books: [] };
        userLists.forEach((list) => {
          newLists[list.category] = list.items; // items are [{ displayName, image }, ...]
        });
        setLists(newLists);
      } catch (error) {
        console.error(error);
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        navigate("/auth");
      }
    })();
  }, [token, navigate]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchQuery("");
    setSearchResults([]);
  };
  const currentCategory = categories[activeTab];
  useEffect(() => {
    if (!searchQuery) {
      setSearchResults([]);
      return;
    }

    const fetchData = async () => {
      try {
        let results = [];
        if (currentCategory === "movies") {
          results = await fetchMovies(searchQuery);
        } else if (currentCategory === "music") {
          results = await fetchMusic(searchQuery);
        } else if (currentCategory === "games") {
          results = await fetchGames(searchQuery);
        } else if (currentCategory === "books") {
          results = await fetchBooks(searchQuery);
        }
        setSearchResults(results);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [searchQuery, currentCategory]);
  const handleAddItem = async (item) => {
    try {
      const updatedList = await addToList(token, currentCategory, item);
      setLists((prev) => ({ ...prev, [currentCategory]: updatedList.items }));
      setSearchQuery("");
      setSearchResults([]);
    } catch (err) {
      console.error(err);
    }
  };
  const handleRemoveItem = async (item) => {
    try {
      const updatedList = await removeFromList(token, currentCategory, item);
      setLists((prev) => ({ ...prev, [currentCategory]: updatedList.items }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleCopyUrl = () => {
    const shareUrl = `${window.location.origin}/u/${localStorage.getItem(
      "username"
    )}`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert(
        `Copied to clipboard: ${shareUrl}. Feel free to share this URL in your social media bios! :)`
      );
    });
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: `
          linear-gradient(
            to bottom right,
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.6)
          ),
          url('https://images.unsplash.com/photo-1557683316-973673baf926?q=80&w=2029&auto=format&fit=crop&ixlib=rb-4.0.3')
        `,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          py: 4,
          mt: 4,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(10px)",
          borderRadius: 2,
          boxShadow: "0 8px 32px rgba(0,0,0,0.4)",
          color: "#fff",
        }}
      >
        <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
          <Typography variant="body1" sx={{ mr: 2 }}>
            Click to copy your MYLIST URL and share with the world:
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: ACCENT_COLOR,
              color: "#000",
              textTransform: "none",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#159c42",
              },
            }}
            onClick={handleCopyUrl}
          >
            Copy MYLIST URL
          </Button>
        </Box>

        {/* Tabs for categories */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="inherit"
          TabIndicatorProps={{ style: { backgroundColor: ACCENT_COLOR } }}
          sx={{
            mb: 3,
            // Make tab labels white, with accent hover
            ".MuiTab-root": {
              textTransform: "none",
              color: "#ccc",
              fontWeight: 500,
            },
            ".MuiTab-root.Mui-selected": {
              color: "#fff",
            },
          }}
        >
          <Tab label="Movies" />
          <Tab label="Games" />
          <Tab label="Music" />
          <Tab label="Books" />
        </Tabs>

        {/* Search bar + dropdown */}
        <Box sx={{ position: "relative", maxWidth: 400 }}>
          <TextField
            fullWidth
            label={`Search ${currentCategory}...`}
            placeholder="Enter at least 2 characters to search"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              label: { color: "#ccc" },
              fieldset: { borderColor: "#666" },
              input: { color: "#fff" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#666",
                },
                "&:hover fieldset": {
                  borderColor: "#fff",
                },
                "&.Mui-focused fieldset": {
                  borderColor: ACCENT_COLOR,
                },
              },
            }}
          />
          {searchResults.length > 0 && (
            <Paper
              elevation={3}
              sx={{
                position: "absolute",
                width: "100%",
                mt: 1,
                zIndex: 10,
                backgroundColor: "rgba(0,0,0,0.8)",
                color: "#fff",
                backdropFilter: "blur(6px)",
              }}
            >
              <List dense>
                {searchResults.map((res, idx) => (
                  <ListItem
                    button
                    key={idx}
                    onClick={() =>
                      handleAddItem({
                        displayName: res.displayName,
                        image: res.image,
                      })
                    }
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "rgba(255,255,255,0.1)",
                      },
                    }}
                  >
                    {res.image && (
                      <CardMedia
                        component="img"
                        image={res.image}
                        alt="img"
                        sx={{
                          width: 40,
                          height: 40,
                          objectFit: "cover",
                          mr: 2,
                          borderRadius: 1,
                        }}
                      />
                    )}
                    <Typography>{res.displayName}</Typography>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
        </Box>

        {/* Display user's favorites in a grid of cards */}
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={3}>
            {lists[currentCategory]?.map((item, i) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    backgroundColor: "rgba(255,255,255,0.05)",
                    backdropFilter: "blur(4px)",
                    boxShadow: "0 4px 16px rgba(0,0,0,0.3)",
                    border: "1px solid rgba(255,255,255,0.1)",
                  }}
                >
                  {/* If there's an image, show it at the top */}
                  {item.image && (
                    <CardMedia
                      component="img"
                      image={item.image}
                      alt={item.displayName}
                      sx={{
                        height: 200,
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "#fff",
                    }}
                  >
                    <Typography variant="body1" sx={{ mr: 2 }}>
                      {item.displayName}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleRemoveItem(item)}
                      sx={{
                        color: "#f44336",
                        "&:hover": {
                          backgroundColor: "rgba(244,67,54,0.1)",
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
