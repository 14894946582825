import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import ReactGA from "react-ga4";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import MovieIcon from "@mui/icons-material/Movie";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { fetchPublicLists } from "../api";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASURE_KEY);
const ACCENT_COLOR = "#1DB954";

const categoryIcons = {
  movies: <MovieIcon sx={{ fontSize: 28, mr: 1 }} />,
  games: <SportsEsportsIcon sx={{ fontSize: 28, mr: 1 }} />,
  music: <MusicNoteIcon sx={{ fontSize: 28, mr: 1 }} />,
  books: <MenuBookIcon sx={{ fontSize: 28, mr: 1 }} />,
};

const { GoogleGenerativeAI } = require("@google/generative-ai");
const genAI = new GoogleGenerativeAI(
  process.env.REACT_APP_GOOGLE_GEMINI_API_KEY
);

export default function PublicListPage() {
  const { username } = useParams();
  const navigate = useNavigate();
  const [lists, setLists] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [descExpanded, setDescExpanded] = useState(false);
  const [likeCount, setLikeCount] = useState(null);
  const [likersList, setLikersList] = useState([]);
  const [descData, setDescData] = useState("Loading...");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/u/${username}`,
      title: `View page hit for ${username}'s list`,
    });

    const fetchLists = async () => {
      try {
        setLoading(true);
        const data = await fetchPublicLists(username);
        setLists(data);
      } catch (err) {
        setError(err.message || "Failed to fetch public lists");
      } finally {
        setLoading(false);
      }
    };

    fetchLists();
  }, [username]);

  useEffect(() => {
    const fetchLikes = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/users/${username}/like`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setLikeCount(data.likeCount);
          setLikersList(data.likedBy);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchLikes();
  }, [username]);

  async function handleLike() {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/users/${username}/like`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username: localStorage.getItem("username") }),
        }
      );
      const data = await res.json();
      if (res.ok) {
        setLikeCount(data.likeCount);
        alert(data.message || "Profile liked!");
      } else {
        alert(data.error || data.message || "Error liking profile");
      }
    } catch (err) {
      console.error(err);
      alert("Error liking profile");
    }
  }

  function displayLikersNames() {
    return (
      <>
        {" ("}
        {likersList.map((likedBy, index) => (
          <span key={index}>
            <Link to={`/u/${likedBy}`}>{likedBy}</Link>
            {index < likersList.length - 1 && ", "}
          </span>
        ))}
        {")"}
      </>
    );
  }

  const handleFetchDescription = async () => {
    try {
      let content = `This is the favourites for ${username.toLowerCase()}: ${JSON.stringify(
        lists
      )}, analyze and give a brief description of ${username.toLowerCase()}'s taste and preference (use no special characters except , ' " and .)`;
      const generationConfig = { temperature: 0.7 };
      const safetySettings = [
        {
          category: "HARM_CATEGORY_SEXUALLY_EXPLICIT",
          threshold: "BLOCK_NONE",
        },
        { category: "HARM_CATEGORY_HATE_SPEECH", threshold: "BLOCK_NONE" },
        { category: "HARM_CATEGORY_HARASSMENT", threshold: "BLOCK_NONE" },
        {
          category: "HARM_CATEGORY_DANGEROUS_CONTENT",
          threshold: "BLOCK_NONE",
        },
      ];
      const model = genAI.getGenerativeModel({
        model: "gemini-1.5-pro-latest",
        generationConfig,
        safetySettings,
      });
      const result = await model.generateContent(content);
      const response = await result.response;
      const text = response.text();
      setDescData(text);
      return text;
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          background: `
            linear-gradient(
              to bottom right,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.6)
            ),
            url('https://images.unsplash.com/photo-1557683316-973673baf926?q=80&w=2029&auto=format&fit=crop&ixlib=rb-4.0.3')
          `,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            p: 2,
            bgcolor: "rgba(0,0,0,0.5)",
            borderRadius: 2,
          }}
        >
          Loading {username}'s favorites...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          background: `
            linear-gradient(
              to bottom right,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.6)
            ),
            url('https://images.unsplash.com/photo-1557683316-973673baf926?q=80&w=2029&auto=format&fit=crop&ixlib=rb-4.0.3')
          `,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          color="error"
          sx={{
            p: 2,
            bgcolor: "rgba(0,0,0,0.5)",
            borderRadius: 2,
          }}
        >
          {error}
        </Typography>
      </Box>
    );
  }

  if (!lists || lists.length === 0) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          background: `
            linear-gradient(
              to bottom right,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.6)
            ),
            url('https://images.unsplash.com/photo-1557683316-973673baf926?q=80&w=2029&auto=format&fit=crop&ixlib=rb-4.0.3')
          `,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ p: 3, bgcolor: "rgba(0,0,0,0.5)", borderRadius: 2 }}>
          <Typography variant="h5" gutterBottom>
            "{username}" has not added anything to their list yet.
          </Typography>
          {!localStorage.getItem("username") && (
            <Typography variant="body1">
              Help break the ice by creating your own list of favorites
            </Typography>
          )}
          {!localStorage.getItem("username") && (
            <Button
              variant="contained"
              sx={{
                mt: 1,
                backgroundColor: ACCENT_COLOR,
                color: "#000",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#159c42",
                },
              }}
              onClick={() => navigate("/")}
            >
              Click here
            </Button>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: `
          linear-gradient(
            to bottom right,
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.6)
          ),
          url('https://images.unsplash.com/photo-1557683316-973673baf926?q=80&w=2029&auto=format&fit=crop&ixlib=rb-4.0.3')
        `,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "#fff",
        py: { xs: 4, md: 8 },
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          py: { xs: 2, md: 4 },
          px: { xs: 2, md: 4 },
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(10px)",
          borderRadius: 2,
          boxShadow: "0 8px 32px rgba(0,0,0,0.4)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "center", sm: "center" },
            textAlign: { xs: "center", sm: "left" },
            mb: 4,
            borderBottom: "1px solid rgba(255,255,255,0.2)",
            pb: 2,
            gap: { xs: 2, sm: 2 },
          }}
        >
          <StarBorderIcon
            sx={{
              fontSize: { xs: 28, sm: 48 },
              color: ACCENT_COLOR,
            }}
          />
          <Box>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 400,
                lineHeight: 1.2,
                fontSize: { xs: "1.6rem", sm: "2.5rem" },
                mb: { xs: 1, sm: 0 },
              }}
            >
              {username.toLowerCase()}'s list
              {username !== localStorage.getItem("username") &&
                !likersList.includes(localStorage.getItem) && (
                  <IconButton
                    onClick={handleLike}
                    sx={{
                      color: "red",
                      "&:hover": {
                        backgroundColor: "rgba(255,0,0,0.1)",
                      },
                    }}
                  >
                    <FavoriteIcon />
                  </IconButton>
                )}
            </Typography>
            {
              <p>
                {username !== localStorage.getItem("username") && likeCount < 1
                  ? `Be the first one to like ${username}'s list!`
                  : `${likeCount || 0} ${likeCount === 1 ? "like" : "likes"}`}
                {likersList.length > 0 ? displayLikersNames() : null}
              </p>
            }
            {!localStorage.getItem("username") && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "center", sm: "flex-start" },
                  gap: { xs: 1, sm: 2 },
                  mt: { xs: 1, sm: 0 },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#ccc",
                    fontSize: { xs: "0.9rem", sm: "1rem" },
                  }}
                >
                  Make it easier for others to connect with you by creating your
                  list!
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: ACCENT_COLOR,
                    color: "#000",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#159c42",
                    },
                  }}
                  onClick={() => navigate("/")}
                >
                  Click here
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        {lists.map((list) => (
          <Box key={list._id} sx={{ mb: { xs: 3, md: 4 } }}>
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                fontSize: { xs: "1.25rem", sm: "1.5rem" },
              }}
            >
              {categoryIcons[list.category]}
              {list.category.toUpperCase()}
            </Typography>
            <Grid container spacing={3}>
              {list.items.map((item, idx) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      backgroundColor: "rgba(255,255,255,0.05)",
                      backdropFilter: "blur(4px)",
                      boxShadow: "0 4px 16px rgba(0,0,0,0.3)",
                      border: "1px solid rgba(255,255,255,0.1)",
                    }}
                  >
                    {item.image && (
                      <CardMedia
                        component="img"
                        image={item.image}
                        alt={item.displayName}
                        sx={{ height: 200, objectFit: "cover" }}
                      />
                    )}
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#fff",
                          fontSize: { xs: "0.9rem", sm: "1rem" },
                        }}
                      >
                        {item.displayName}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}

        <Box sx={{ mt: { xs: 2, md: 4 } }}>
          <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
            MYLIST AI
          </Typography>
          {localStorage.getItem("username") ? (
            <Accordion
              expanded={descExpanded}
              onChange={(event, isExpanded) => {
                if (!descExpanded && isExpanded) {
                  handleFetchDescription();
                  setDescExpanded(true);
                }
              }}
              sx={{
                backgroundColor: "rgba(255,255,255,0.05)",
                backdropFilter: "blur(4px)",
                boxShadow: "0 4px 16px rgba(0,0,0,0.3)",
                border: "1px solid rgba(255,255,255,0.1)",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "#fff", fontWeight: 500 }}
                >
                  Can AI decipher {username} with the help of their favorites?
                  Click to see!
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#ccc",
                    fontSize: { xs: "0.9rem", sm: "1rem" },
                  }}
                >
                  {descData}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : (
            <Typography
              variant="body1"
              sx={{
                color: "#fff",
                fontWeight: 500,
                textAlign: "center",
                mt: 2,
              }}
            >
              <a href="/auth">Log in</a> to see AI-generated description
            </Typography>
          )}
        </Box>
      </Container>
    </Box>
  );
}
