import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box, GlobalStyles } from "@mui/material";

import Header from "./components/Header";
import Footer from "./components/Footer";
import ListPage from "./pages/ListPage";
import PublicListPage from "./pages/PublicListPage";
import AuthPage from "./pages/AuthPage";
import { Helmet } from "react-helmet";
import AllUsersPage from "./pages/AllUsersPage";

export default function App() {
  return (
    <>
      <GlobalStyles
        styles={{
          "html, body": {
            margin: 0,
            padding: 0,
            overflowX: "hidden",
          },
        }}
      />
      <Box
        sx={{
          // 1) The main background for the entire app
          minHeight: "100vh",
          background: `
          linear-gradient(
            to bottom right,
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.6)
          ),
          url('https://images.unsplash.com/photo-1557683316-973673baf926?q=80&w=2029&auto=format&fit=crop')
        `,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Helmet>
          <title>MyList - Share Your Favorites</title>
          <meta
            name="description"
            content="Collect, share, and discover favorites from movies to music — MyList is your gateway to curated content."
          />
          <meta property="og:title" content="MyList - Share Your Favorites" />
          <meta
            property="og:description"
            content="Collect, share, and discover favorites from movies to music — MyList is your gateway to curated content."
          />
          <meta property="og:url" content="https://mylist.rocks" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="MyList - Share Your Favorites" />
          <meta
            name="twitter:description"
            content="Collect, share, and discover favorites from movies to music—MyList is your gateway to curated content."
          />
        </Helmet>
        <BrowserRouter>
          <Header />

          <Routes>
            <Route path="/" element={<AuthPage />} />
            <Route path="/list" element={<ListPage />} />
            <Route path="/u/:username" element={<PublicListPage />} />
            <Route path="/all" element={<AllUsersPage />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </Box>
    </>
  );
}
