import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import logo from "../logo.png";

export default function Header() {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    navigate("/");
  };

  // Items we show in the nav. We’ll reuse them in both the drawer and the desktop bar
  const navItems = [
    {
      label: "Login/Register",
      visible: !localStorage.getItem("username"),
      onClick: () => navigate("/"),
    },
    {
      label: "Modify MYLIST",
      visible: !!localStorage.getItem("username"),
      onClick: () => navigate("/list"),
    },
    {
      label: "View MYLIST",
      visible: !!localStorage.getItem("username"),
      onClick: () => navigate(`/u/${localStorage.getItem("username")}`),
    },
    {
      label: "All Lists",
      visible: true,
      onClick: () => navigate("/all"),
    },
    {
      label: "Logout",
      visible: !!localStorage.getItem("username"),
      onClick: handleLogout,
    },
  ];

  // Drawer content on mobile
  const drawerContent = (
    <Box
      sx={{
        width: 250,
        backgroundColor: "#111",
        height: "100%",
        color: "#fff",
      }}
      onClick={() => setDrawerOpen(false)}
    >
      <List>
        {navItems.map((item, idx) => {
          if (!item.visible) return null;
          return (
            <ListItem key={idx} disablePadding>
              <ListItemButton onClick={item.onClick}>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: { xs: 1, sm: 2, md: 3 },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="MyList Logo" style={{ height: 90, width: 90 }} />
        </Box>

        {/* Desktop Nav (hidden on small screens) */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            gap: 1,
          }}
        >
          {navItems.map((item, idx) => {
            if (!item.visible) return null;
            return (
              <Button
                key={idx}
                onClick={item.onClick}
                sx={{
                  color: "#fff",
                  textTransform: "none",
                  fontWeight: 500,
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                }}
              >
                {item.label}
              </Button>
            );
          })}
        </Box>

        {/* Mobile Menu Icon (hidden on medium+ screens) */}
        <IconButton
          sx={{ display: { xs: "block", md: "none" }, color: "#fff" }}
          onClick={() => setDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      {/* Drawer for mobile nav */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#111",
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </AppBar>
  );
}
