// src/components/Footer.js
import React from "react";
import { Box, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        background: `linear-gradient(
          to right,
          rgba(0, 0, 0, 0.6),
          rgba(0, 0, 0, 0.4)
        )`,
        backdropFilter: "blur(10px)",
        boxShadow: "0 -4px 12px rgba(0,0,0,0.3)",
        borderTop: "1px solid rgba(255,255,255,0.2)",
        color: "#fff",
        textAlign: "center",
        p: 2,
        mt: 4,
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        Built with ❤️ by{" "}
        <a href="https://www.linkedin.com/in/priyanshu-shukla/">Priyanshu</a>{" "}
      </Typography>
    </Box>
  );
}
