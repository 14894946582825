import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from "react-ga4";
import logo from "../logo.png";
import { registerUser, loginUser } from "../api";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASURE_KEY);

export default function AuthPage() {
  const navigate = useNavigate();
  const [tab, setTab] = useState("login");
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [regUsername, setRegUsername] = useState("");
  const [regEmail, setRegEmail] = useState("");
  const [regPassword, setRegPassword] = useState("");
  const [regError, setRegError] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isRegisterValid, setIsRegisterValid] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Authentication hit",
    });

    if (localStorage.getItem("username")) {
      navigate("/list");
    }

    const usernameValid = regUsername.trim().length >= 3;
    const emailValid = /\S+@\S+\.\S+/.test(regEmail);
    const passwordValid = validatePassword(regPassword);
    const captchaValid = !!captchaToken;

    setIsRegisterValid(
      usernameValid && emailValid && passwordValid && captchaValid
    );
  }, [navigate, regUsername, regEmail, regPassword, captchaToken]);

  function validatePassword(password) {
    if (password.length < 8) return false;
    if (!/[A-Z]/.test(password)) return false;
    if (!/\d/.test(password)) return false;
    return true;
  }

  function onCaptchaChange(token) {
    setCaptchaToken(token);
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await loginUser(loginUsername, loginPassword);
      localStorage.setItem("token", data.token);
      localStorage.setItem("username", data.username);
      navigate("/list");
    } catch (err) {
      alert(err.message || "Login failed");
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!validatePassword(regPassword)) {
      setRegError(
        "Password must be at least 8 characters, contain an uppercase letter and a digit."
      );
      return;
    }
    if (!captchaToken) {
      setRegError("Please complete the reCAPTCHA.");
      return;
    }
    try {
      await registerUser(regUsername, regEmail, regPassword, captchaToken);
      alert("Registration successful! Please log in.");
      setTab("login");
      setCaptchaToken(null);
    } catch (err) {
      alert(err.message || "Registration failed");
    }
  };

  return (
    <div className="auth-page">
      <div className="intro-section">
        <h1 className="app-brand">
          <img
            src={logo}
            alt="MyList Logo"
            style={{ height: 120, width: 120 }}
          />
        </h1>
        <p>
          Collect, share, and discover favorites from movies, games, books to
          music — let the world see what you love, and explore what others
          recommend!
        </p>
      </div>

      <div className="auth-card">
        <div className="tabs">
          <button
            className={`tab-btn ${tab === "login" ? "active" : ""}`}
            onClick={() => setTab("login")}
          >
            Login
          </button>
          <button
            className={`tab-btn ${tab === "register" ? "active" : ""}`}
            onClick={() => setTab("register")}
          >
            Register
          </button>
        </div>
        {tab === "login" && (
          <form onSubmit={handleLogin} className="form-animate">
            <label>Username</label>
            <input
              type="text"
              placeholder="Enter your username"
              value={loginUsername}
              onChange={(e) => setLoginUsername(e.target.value)}
              required
            />
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
              required
            />
            <button type="submit" className="submit-btn">
              Login
            </button>
          </form>
        )}
        {tab === "register" && (
          <form onSubmit={handleRegister} className="form-animate">
            <label>Username</label>
            <input
              type="text"
              placeholder="Choose a username (min 3 chars)"
              value={regUsername}
              onChange={(e) => {
                setRegUsername(e.target.value);
                setRegError("");
              }}
              required
            />
            <label>Email Address</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={regEmail}
              onChange={(e) => {
                setRegEmail(e.target.value);
                setRegError("");
              }}
              required
            />
            <label>Password</label>
            <input
              type="password"
              placeholder="At least 8 chars, 1 uppercase, 1 digit"
              value={regPassword}
              onChange={(e) => {
                setRegPassword(e.target.value);
                setRegError("");
              }}
              required
            />
            {regError && (
              <p style={{ color: "red", fontSize: "0.9rem" }}>{regError}</p>
            )}
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
              onChange={onCaptchaChange}
            />
            <button
              type="submit"
              className="submit-btn"
              disabled={!isRegisterValid}
            >
              Register
            </button>
          </form>
        )}
      </div>

      <style jsx>{`
        @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
        * {
          box-sizing: border-box;
        }
        html,
        body,
        #root {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          font-family: "Poppins", sans-serif;
          -webkit-text-size-adjust: none;
        }
        .auth-page {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100vw;
          height: 100vh;
          background: linear-gradient(
              to bottom right,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.6)
            ),
            url("https://images.unsplash.com/photo-1557683316-973673baf926?q=80&w=2029&auto=format&fit=crop&ixlib=rb-4.0.3");
          background-size: cover;
          background-position: center;
          overflow-x: hidden;
          text-align: center;
          padding: 1rem;
        }
        .intro-section {
          max-width: 600px;
          margin-bottom: 1.5rem;
          color: #fff;
          line-height: 1.4;
        }
        .intro-section h2 {
          font-size: 2rem;
          margin-bottom: 0.5rem;
        }
        .intro-section p {
          font-size: 1rem;
          color: #ccc;
          margin: 0 auto;
        }
        .auth-card {
          width: 380px;
          max-width: 90%;
          padding: 2rem;
          border-radius: 1rem;
          background: rgba(30, 30, 30, 0.6);
          backdrop-filter: blur(12px);
          box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
          border: 1px solid rgba(255, 255, 255, 0.15);
          color: #fff;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          text-align: left;
          margin: 0 auto;
        }
        .app-brand {
          margin: 0;
          text-align: center;
        }
        .tabs {
          display: flex;
          gap: 0.5rem;
          justify-content: center;
        }
        .tab-btn {
          flex: 1;
          padding: 0.6rem 1rem;
          background: transparent;
          border: 2px solid #1db954;
          border-radius: 4px;
          color: #fff;
          font-weight: 500;
          cursor: pointer;
          transition: background 0.3s;
        }
        .tab-btn:hover {
          background: rgba(29, 185, 84, 0.15);
        }
        .tab-btn.active {
          background: #1db954;
          color: #000;
        }
        form {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
        form label {
          font-weight: 500;
        }
        form input {
          padding: 0.75rem;
          border: none;
          border-radius: 4px;
          background: rgba(255, 255, 255, 0.1);
          color: #fff;
          font-size: 16px;
        }
        form input::placeholder {
          color: #ccc;
        }
        form input:focus {
          outline: 2px solid #1db954;
        }
        .submit-btn {
          padding: 0.75rem;
          border: none;
          border-radius: 4px;
          background: #1db954;
          color: #000;
          font-weight: 600;
          cursor: pointer;
          transition: background 0.3s;
          font-size: 16px;
        }
        .submit-btn:hover {
          background: #159c42;
        }
        .submit-btn:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        .form-animate {
          animation: fadeIn 0.3s ease-in-out;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        @media (max-width: 480px) {
          .auth-card {
            padding: 1.5rem;
          }
          .intro-section h2 {
            font-size: 1.6rem;
          }
          .intro-section p {
            font-size: 0.9rem;
          }
        }
      `}</style>
    </div>
  );
}
