export const BASE_URL = process.env.REACT_APP_BASE_URL;

export async function registerUser(username, email, password) {
  username = username.toLowerCase();
  const res = await fetch(`${BASE_URL}/register`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, email, password }),
  });
  if (!res.ok) {
    const errData = await res.json();
    throw new Error(errData.error || "Registration failed");
  }
  return res.json();
}

export async function loginUser(username, password) {
  username = username.toLowerCase();
  const res = await fetch(`${BASE_URL}/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  });
  if (!res.ok) {
    const errData = await res.json();
    throw new Error(errData.error || "Login failed");
  }
  return res.json(); // { token: '...', ... }
}

export async function getUserLists(token) {
  const res = await fetch(`${BASE_URL}/lists`, {
    headers: {
      Authorization: token,
    },
  });
  if (!res.ok) {
    const errData = await res.json();
    throw new Error(errData.error || "Failed to fetch lists");
  }
  return res.json(); // [ { category, items: [] }, ... ]
}

export async function addToList(token, category, item) {
  // item should be an object like { displayName: "Title", image: "http://..." }
  const res = await fetch(`${BASE_URL}/lists`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ category, item }),
  });

  if (!res.ok) {
    const errData = await res.json().catch(() => ({}));
    throw new Error(errData.error || "Failed to add item");
  }
  return res.json();
}

export async function removeFromList(token, category, item) {
  const res = await fetch(`${BASE_URL}/lists`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ category, item }),
  });
  if (!res.ok) {
    const errData = await res.json();
    throw new Error(errData.error || "Failed to remove item");
  }
  return res.json(); // updated list
}

// -----------------
// External APIs
// -----------------

// 1) Movies: OMDb
export async function fetchMovies(query) {
  if (!query) return [];
  const url = `https://api.themoviedb.org/3/search/movie?api_key=${
    process.env.REACT_APP_TMBD_API_KEY
  }&query=${encodeURIComponent(query)}`;

  try {
    const res = await fetch(url);
    const data = await res.json();

    if (data.results) {
      return data.results.map((movie) => {
        // The TMDB poster path needs to be combined with a base URL
        // For example: https://image.tmdb.org/t/p/w500/ + poster_path
        const posterUrl = movie.poster_path
          ? `https://image.tmdb.org/t/p/w500${movie.poster_path}`
          : null;
        const year = movie.release_date
          ? movie.release_date.split("-")[0]
          : "N/A";

        return {
          displayName: `${movie.title} (${year})`,
          image: posterUrl,
        };
      });
    }
    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
}

// 2) Music: iTunes
export async function fetchMusic(query) {
  if (!query) return [];

  const url = `https://itunes.apple.com/search?term=${encodeURIComponent(
    query
  )}&limit=5`;
  try {
    const res = await fetch(url);
    const data = await res.json();
    if (data.results) {
      return data.results.map((track) => {
        let biggerArt = null;
        if (track.artworkUrl100) {
          biggerArt = track.artworkUrl100.replace("100x100", "600x600");
        }

        return {
          displayName: `${track.trackName} - ${track.artistName}`,
          image: biggerArt || null,
        };
      });
    }
    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
}

// 3) Games: RAWG
export async function fetchGames(query) {
  if (!query) return [];
  const url = `https://api.rawg.io/api/games?key=${
    process.env.REACT_APP_RAWG_API_KEY
  }&search=${encodeURIComponent(query)}`;
  try {
    const res = await fetch(url);
    const data = await res.json();
    if (data.results) {
      return data.results.map((game) => ({
        displayName: game.name,
        image: game.background_image || null,
      }));
    }
    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
}

// 4) Books: Google Books
export async function fetchBooks(query) {
  if (!query) return [];
  const url = `https://www.googleapis.com/books/v1/volumes?q=${encodeURIComponent(
    query
  )}&maxResults=5`;
  try {
    const res = await fetch(url);
    const data = await res.json();
    if (data.items) {
      return data.items.map((book) => {
        const volumeInfo = book.volumeInfo;
        const title = volumeInfo.title || "No Title";
        const authors = volumeInfo.authors
          ? volumeInfo.authors.join(", ")
          : "Unknown";
        const thumbnail = volumeInfo.imageLinks?.thumbnail || null;
        return {
          displayName: `${title} by ${authors}`,
          image: thumbnail,
        };
      });
    }
    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function fetchPublicLists(username) {
  const res = await fetch(`${BASE_URL}/${username}`);
  if (!res.ok) {
    const errData = await res.json().catch(() => ({}));
    throw new Error(errData.error || "Failed to fetch public lists");
  }
  return res.json();
}
